
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ApiService from '@/core/services/ApiService';
import CourseInfoDrawer from '@/layout/header/partials/course/CourseInfoDrawer.vue';
import CourseConfigDrawer from '@/layout/header/partials/course/CourseConfigDrawer.vue';
import CourseTermDrawer from '@/layout/header/partials/course/CourseTermDrawer.vue';
import CourseBillDrawer from '@/layout/header/partials/course/CourseBillDrawer.vue';
import AddCourseChapter from '@/layout/header/partials/course/AddCourseChapter.vue';
import AddUnitOfCompetency from '@/layout/header/partials/course/AddUnitOfCompetency.vue';
import { Field } from 'vee-validate';
import { VueCookieNext } from "vue-cookie-next";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';

import { apiEndpoint } from '@/mixin/apiMixin';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'course-details-view',
  components: {
    CourseInfoDrawer,
    CourseConfigDrawer,
    CourseTermDrawer,
    Field,
    CourseBillDrawer,
    AddCourseChapter,
    AddUnitOfCompetency,
    Datatable,
  },
  data() {
    return {
      milestoneList: [],
      componentChapterKey: 0,
      componentUnitofCompentencyKey: 0,
      updateLogCourseTermFilter: [] as any,
      updateLogCourseInfo: [] as any,
      updateLogCourseConfig: [] as any,
      updateLogCourseTerm: [] as any,
      updateLogCourseBill: [] as any,
      courseChapterData: [] as any,
      unitOfCompetencyData: [] as any,
      componentKey: 0,
      load: false,
      id: '' as any,
      actionApprove: false,
      actionReject: false,
      actionEdit: false,
      checkCourseEdit: true,
      // courseDetails: {
      //   entity_tranche_contract_id: "",
      // },
      updateLog: [],
      courseChapterHeader: [
        {
          name: 'Action',
          key: 'actions',
          sortable: true,
        },
        {
          name: 'Chapter Name',
          key: 'chapter_name',
          sortable: true,
        },
      ],
      uniOfCompetencyHeader: [
        {
          name: 'Action',
          key: 'actions',
          sortable: true,
        },
        {
          name: 'Unit Name',
          key: 'unit_name',
          sortable: true,
        },
      ],
      percentageInfo: '',
      entity_type: '',
      courseDetails: {
        term: 0,
        course_term_info: [
          // {
          //   "no": 0 ,
          //   "month":"",
          //   "hour": "",
          //   "day":"",
          //   "session":"",
          // }
        ],
      } as any,
      tabIndex: ref(0),
      terms: [] as any,
      bills: [] as any,
    };
  },
  async created() {
    this.id = this.$route.params.id;
    this.entity_type = VueCookieNext.getCookie('_seip_entity_type');
    await this.getMilestoneList();
    this.emitter.on('unit-of-competency-added', async () => {
      this.load = true;
      await this.getUnitOfCompentencyList();
    });
    this.emitter.on('chapter-added', async () => {
      this.load = true;
      await this.getCourseChapterList();
    });
    // this.load = true;
    await this.getCourseDetails();
    await this.courseLog();
    await this.courseConfigLog();
    await this.getCourseChapterList();
    await this.getUnitOfCompentencyList();
    // // await this.courseTermLog();
    await this.filterTermLog();
    await this.courseBillFilter();
    await this.actioncheck();
    this.emitter.on('course-approve-update', async () => {
      await this.getCourseDetails();
      await this.courseLog();
      await this.courseConfigLog();
      await this.filterTermLog();
      await this.courseBillFilter();
      await this.getCourseChapterList();
      await this.getUnitOfCompentencyList();
    });
    // await this.getContractPercentage(
    //   this.courseDetails.entity_tranche_contract_id
    // );
    if(VueCookieNext.getCookie('_seip_role_id') == 1){
      this.checkCourseEdit = false;
    }
  },
  methods: {
    async getMilestoneList() {
      await ApiService.get('configurations/milestone_type/list')
        .then((response) => {
          this.milestoneList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    editUnitofCompetency() {
      console.log('unit of competency data' + this.id);
      this.emitter.emit('unit-of-competency-edit', this.id);
    },
    addUnitofCompetency() {
      console.log('unit of competency data' + this.id);
      this.emitter.emit('unit-of-competency-add', this.id);
    },
    addChapter() {
      console.log('chapter data' + this.id);
      this.emitter.emit('chapter-add', this.id);
    },
    editChapter(data) {
      this.emitter.emit('chapter-edit', data);
    },
    Reject(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to reject it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Rejected!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(this.VUE_APP_COURSE_REJECT_API + `${this.id}`, data)
            .then((response) => {
              this.emitter.emit('course-approve-update', true);
              this.componentKey += 1;

              Swal.fire('Rejected!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Approve() {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to approve it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approved!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(
            this.VUE_APP_COURSE_APPROVE_API + `${this.id}`,
            data
          )
            .then((response) => {
              this.emitter.emit('course-approve-update', true);
              this.componentKey += 1;

              Swal.fire('Approved!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    async chapterDelete(id) {
      await ApiService.get(this.VUE_APP_COURSE_CHAPTER_DELETE_API + id)
        .then((response) => {
          if (response.data.status == 'success') {
            Swal.fire({
              title: 'Success!',
              text: 'Chapter Deleted Successfully',
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.emitter.emit('chapter-added', true);
            });
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == 'Course') {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            //console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Approve') {
                this.actionApprove = true;
              }
              if (actions[j].action_name === 'Edit') {
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Reject') {
                this.actionReject = true;
              }
            }
          }
        }
      }
    },
    async filterTermLog() {
      this.load = true;
      await ApiService.get('course/courseTermFilter/' + this.id)
        .then((response) => {
          this.updateLogCourseTermFilter = [] as any;
          if (response.data.data) {
            this.updateLogCourseTermFilter = response.data.data;
            // console.log(this.updateLogCourseTermFilter);

            for (let i = 0; i < this.updateLogCourseTermFilter.length; i++) {
              //let arr=JSON.parse(this.updateLogEducation[i]);
              let count = Object.keys(this.updateLogCourseTermFilter[i]).length;
              this.updateLogCourseTermFilter[i]['count'] = count;
            }

            this.load = false;
          }
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },

    async courseBillFilter() {
      this.load = true;
      await ApiService.get('course/courseBillFilter/' + this.id)
        .then((response) => {
          this.load = false;
          this.updateLogCourseBill = [] as any;
          if (response.data.data) {
            this.updateLogCourseBill = response.data.data;

            for (let i = 0; i < this.updateLogCourseBill.length; i++) {
              //let arr=JSON.parse(this.updateLogEducation[i]);
              let count = Object.keys(this.updateLogCourseBill[i]).length;
              this.updateLogCourseBill[i]['count'] = count;
            }
          }

          console.log(this.updateLogCourseBill);
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },

    async courseTermLog() {
      this.load = true;
      await ApiService.get('course/courseTermEdit/' + this.id)
        .then((response) => {
          if (response.data.data) {
            this.updateLogCourseTerm = response.data.data;
            console.log(this.updateLogCourseTerm);

            for (let i = 0; i < this.updateLogCourseTerm.length; i++) {
              //let arr=JSON.parse(this.updateLogEducation[i]);
              let count = Object.keys(this.updateLogCourseTerm[i]).length;
              this.updateLogCourseTerm[i]['count'] = count;
            }

            this.load = false;
          }
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    async courseConfigLog() {
      await ApiService.get('course/courseConfigEdit/' + this.id)
        .then((response) => {
          if (response.data.data) {
            this.updateLogCourseConfig = response.data.data;
            this.load = false;
          }
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    async courseLog() {
      this.load = true;
      await ApiService.get('course/edit-filter/' + this.id)
        .then((response) => {
          if (response.data.data) {
            this.updateLogCourseInfo = response.data.data;
          }
          this.load = false;
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    batchrunning() {
      Swal.fire({
        text: 'Batch Already Running!',
        icon: 'warning',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      });
    },
    billrunning() {
      Swal.fire({
        text: 'Bill Can Not Edit!',
        icon: 'warning',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      });
    },
    editBill() {
      this.emitter.emit('course_bill_update', this.id);
    },
    editcourse() {
      this.emitter.emit('course_info_update', this.id);
    },
    editTerm() {
      this.emitter.emit('course_term_update', this.id);
    },
    editcourseConfig() {
      this.emitter.emit('course_config_update', this.id);
    },
    async getContractPercentage(id) {
      this.load = true;
      await ApiService.get('entity/contract/show/' + id)
        .then((response) => {
          this.load = false;
          this.percentageInfo = response.data.data.contribution_percentage;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseDetails() {
      this.load = true;
      await ApiService.get('course/show/' + `${this.id}`)
        .then((response) => {
          this.load = false;
          this.courseDetails = response.data.data;
          console.log(this.courseDetails);
          if(VueCookieNext.getCookie('_seip_role_id') != 1 && this.courseDetails?.batch_info?.length){
            this.checkCourseEdit = true;
          }
          this.terms = response.data.data.course_term_info;
          this.bills = response.data.data.course_bill_info;
          for (let i = 0; i < this.bills.length; i++) {}

          // for (let i = 0; i < this.courseDetails.term; i++) {
          //   let temp_term = {
          //     id: "" as any,
          //     course_info_id: "" as any,
          //     term_no: "" as any,
          //     term_month: "" as any,
          //     term_day: "" as any,
          //     term_session: "" as any,
          //     term_hour: "" as any,
          //     class_type: "" as any,
          //     week_session: "" as any,
          //   };
          //   temp_term.course_info_id = courseterm[i].course_info_id;
          //   temp_term.term_no = courseterm[i].term_no;
          //   temp_term.term_month = courseterm[i].term_month;
          //   temp_term.term_day = courseterm[i].term_day;
          //   temp_term.term_session = courseterm[i].term_session;
          //   temp_term.term_hour = courseterm[i].term_hour;
          //   temp_term.class_type = courseterm[i].class_type;
          //   temp_term.week_session = courseterm[i].week_session;
          //   this.terms.push(temp_term);
          // }
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    setActiveTab(event) {
      console.log(parseInt(event.target.getAttribute('data-tab-index')));

      this.tabIndex = parseInt(event.target.getAttribute('data-tab-index'));
    },
    async getUnitOfCompentencyList() {
      await ApiService.get(
        this.VUE_APP_UNIT_OF_COMPETENCY_List_API + '?course_id=' + this.id
      )
        .then((response) => {
          console.log(response);
          this.unitOfCompetencyData = response.data.data;
          this.componentUnitofCompentencyKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseChapterList() {
      await ApiService.get(
        this.VUE_APP_COURSE_CHAPTER_List_API + '?course_id=' + this.id
      )
        .then((response) => {
          console.log(response);
          this.courseChapterData = response.data.data;
          this.componentChapterKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
